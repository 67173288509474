<template>
  <div class="platform-page">
    <div class="platform container-fluid">
      <div class="container">
        <div class="platform-box">
          <div class="title">
            <span>{{ $t('platform_page.title') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('platform_page.description') }} </span>
          </div>
          <div class="start-button">
            <button>{{ $t('platform_page.start') }}</button>
          </div>
          <div class="scroll-down">
            <span>{{ $t('platform_page.scroll') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="crypto practice container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 bitcoin">
            <img src="./img/dollar.png" alt="dollar" />
          </div>
          <div class="col-md-6 col-sm-6 crypto-sub">
            <div class="crypto-box">
              <div class="low-title">
                <span>{{ $t('platform_page.free') }}</span>
              </div>
              <div class="title">
                <span>{{ $t('platform_page.way') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('platform_page.way_description') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ultimate crypto container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 crypto-sub">
            <div class="crypto-box">
              <div class="low-title">
                <span>{{ $t('platform_page.free') }}</span>
              </div>
              <div class="title">
                <span>{{ $t('platform_page.way') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('platform_page.way_descripiton') }}</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-6 bitcoin">
            <img src="./img/ultimate.png" alt="ultimate" />
          </div>
        </div>
      </div>
    </div>
    <div class="exchanges crypto container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 bitcoin">
            <img src="./img/exchanges.png" alt="exchanges" />
          </div>
          <div class="col-md-6 col-sm-6 crypto-sub">
            <div class="crypto-box">
              <div class="low-title">
                <span>{{ $t('platform_page.free') }}</span>
              </div>
              <div class="title">
                <span>{{ $t('platform_page.way') }}</span>
              </div>
              <div class="description">
                <span>{{ $t('platform_page.way_description') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="smart-trading container-fluid">
      <div class="container">
        <div class="smart-trading-box">
          <div class="title">
            <span>{{ $t('platform_page.start_trade') }}</span>
          </div>
          <div class="description">
            <span>{{ $t('platform_page.free_use') }}</span>
          </div>
          <div class="start-button">
            <button>{{ $t('platform_page.start_now') }}</button>
          </div>
        </div>
      </div>
    </div>
    <div class="cards container-fluid">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-sm-4 cards_accept">
            <div class="cards__title">
              <span>{{ $t('platform_page.accept') }}</span>
            </div>
          </div>
          <div class="col-md-8 col-sm-8 cards__list">
            <div class="skrill">
              <img src="~@assets/img/skrill.png" alt="skrill" />
            </div>
            <div class="visa">
              <img src="~@assets/img/visa.png" alt="visa" />
            </div>
            <div class="mc">
              <img src="~@assets/img/mc.png" alt="master_card" />
            </div>
            <div class="neteller">
              <img src="~@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="wire">
              <img src="~@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" src="./styles.scss"></style>
